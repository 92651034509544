export const DEFAULT_VIDEO_SRC =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const playVideoWithPromiseHandle = (
  video: HTMLVideoElement,
  callback?: () => void
) => {
  if (!video) return;

  const promise = video.play();
  if (promise !== undefined) {
    promise
      .catch(() => {
        // NOTE: auto-play was prevented
        // show a UI element to let the user manually start playback
      })
      .then(() => {
        // NOTE: auto-play started
        callback && callback();
      });
  }
};
