import type { MY24HeroBannerProps } from '@/components/sections/my24/home/hero-banner';
import { IMY24LineupCarousel } from '@/components/sections/my24/home/lineup-carousel';
import { getCta } from '@/contents/lcv/product/my24/cta';
import { HOME_LINEUP_DATA } from '@/contents/lcv/product/my24/models';

export const META_TAG_DATA = {
  title: 'ទំនាក់ទំនងមក​យើងខ្ញុំ​ - Isuzu TIS Cambodia',
  description: 'តាមដានព័ត៌មាន និងប្រូម៉ូសិនថ្មីៗរបស់ Isuzu នៅទីនេះ',
  img: {
    url: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg',
  },
};

export const HERO_BANNER_DATA: MY24HeroBannerProps = {
  headline: {},
  items: [
    {
      title:
        'ISUZU D-MAX V-CROSS 4X4<span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt27a3939fded3b33b/6641995ae7f45d3d6d8b150b/banner-v-cross-mobile.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4719efcd8cf20689/6641995af6a9a375c02877a0/banner-v-cross.jpg',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4721b6e8faee609d/651528c9b16d6d5596554560/logo-new-isuzu-v-cross-4x4-white.png',
        alt: 'NEW! ISUZU V-CROSS 4x4',
        width: 246,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-v-cross',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ISUZU D-MAX 4X4<span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt08d73843f21d5a58/6790576b3b4213017405baea/banner-d-max-4x4-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX 4x4',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt25a477c0e5af747c/6790576bd8a19e4f961b4213/banner-d-max-4x4-desktop.jpg',
        alt: 'NEW! ISUZU D-MAX 4x4',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd76e3ff60e1e57b8/6790778fbf520435aa7d0e2a/logo-isuzu-d-max-4x4.png',
        alt: 'NEW! ISUZU D-MAX 4x4',
        width: 246,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-d-max-4x4',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ISUZU D-MAX HI-LANDER <span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt86f08d327e96b2e5/6641995a907afeeb4aaf986c/banner-4door-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf35d6027b32e4a33/6641995aa2616e51abff1d17/banner-4door.jpg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 600,
        height: 338,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt90599ea4859b6c8c/64fd7594b169adb40804b401/logo-new-isuzu-hi-lander-white.svg',
        alt: 'NEW! ISUZU D-MAX HI-LANDER',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-pick-up-4-door',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title:
        'ISUZU D-MAX SPARK <span class="subtitle">UNLOCK YOUR POTENTIAL</span>',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2d74270442abf874/6641995a2ff97d862e704e96/banner-spark-mobile.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdc249682bf6f4e43/6641995a2a72d9184417b189/banner-spark.jpg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 246,
        height: 72,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd531cf0bb3b4ed81/64fd71a1bb60f75a2b4803cc/logo-new-isuzu-spark-white.svg',
        alt: 'NEW! ISUZU D-MAX SPARK',
        width: 217,
        height: 72,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-spark',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
    {
      title: 'ISUZU MU-X THE NEXT PEAK',
      image: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltdd7184f4a6e41078/6791fda6e05cbf3d985631a9/mu-x-banner-mobile.jpg',
        alt: 'MU-X THE NEXT PEAK',
        width: 600,
        height: 800,
      },
      imageDesktop: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf4ba8c80d81da46/6791fda6eabff84bc3657b1c/mu-x-banner-desktop.jpg',
        alt: 'MU-X THE NEXT PEAK',
        width: 246,
        height: 72,
      },
      logo: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb0fdd3e422c531a5/665fdfa4a3c3fe52694d2878/logo-isuzu-mu-x.png',
        alt: 'MU-X THE NEXT PEAK',
        width: 300,
        height: 70,
      },
      buttons: [
        {
          label: 'មើលពត៌មានបន្ថែម',
          url: '/isuzu-mu-x',
          icon: 'arrow-forward',
          color: 'button-white',
          border: true,
        },
      ],
    },
  ],
};

export const LINEUP_CAROUSEL_DATA: IMY24LineupCarousel = {
  active: true,
  headline: {
    title: '',
  },
  items: HOME_LINEUP_DATA,
};

export const CTA_DATA = {
  ...getCta('home'),
};
